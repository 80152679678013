import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

import MainLogo from "../assets/images/logo.png";
import Popup from "./Popup";

const Header = () => {
  const [showDialog, setShowDialog] = useState(false);
  const handlePopup = () => {
    setShowDialog(true);
  };
  const handleCancel = () => {
    setShowDialog(false);
  };
  return (
    <>
      <nav className="navbar navbar-bg navbar-expand-md navbar-dark bg-dark">
        <div className="container">
          <Link className="navbar-brand text-uppercase" to="/">
            <img src={MainLogo} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExample04"
            aria-controls="navbarsExample04"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarsExample04">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item  active">
                <NavLink
                  className="nav-link text-uppercase"
                  to="/"
                  exact={true}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item  active">
                <NavLink
                  className="nav-link text-uppercase"
                  to="/about-us"
                  exact={true}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item  active">
                <NavLink
                  className="nav-link text-uppercase"
                  to="/products"
                  exact={true}
                >
                  Products
                </NavLink>
              </li>
              <li className="nav-item  active">
                <NavLink
                  className="nav-link text-uppercase"
                  to="/services"
                  exact={true}
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item  active">
                <NavLink
                  className="nav-link text-uppercase"
                  to="/contact-us"
                  exact={true}
                >
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item  active">
                <button
                  className="nav-link enquiry_btn text-uppercase"
                  onClick={handlePopup}
                >
                  enquiry
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Popup initState={showDialog} handleCancel={handleCancel} />
    </>
  );
};

export default Header;
