import React from "react";
import { Link } from "react-router-dom";

import bannerOne from "../../assets/images/banner-one.jpg";
import detailBg from "../../assets/images/detail_bg.jpg";
import productbg from "../../assets/images/product_bg.jpg";
import servicesbg from "../../assets/images/services_bg.jpg";

const CategoryItem = () => {
  return (
    <ul>
      <li>
        <Link to="/">
          <figure>
            <img src={bannerOne} alt="" />
            <h4>Cooking Equipment</h4>
          </figure>
        </Link>
      </li>
      <li>
        <Link to="/">
          <figure>
            <img src={detailBg} alt="" />
            <h4>Cooking Equipment</h4>
          </figure>
        </Link>
      </li>
      <li>
        <Link to="/">
          <figure>
            <img src={productbg} alt="" />
            <h4>Cooking Equipment</h4>
          </figure>
        </Link>
      </li>
      <li>
        <Link to="/">
          <figure>
            <img src={servicesbg} alt="" />
            <h4>Cooking Equipment</h4>
          </figure>
        </Link>
      </li>
      <li>
        <Link to="/">
          <figure>
            <img src={bannerOne} alt="" />
            <h4>Cooking Equipment</h4>
          </figure>
        </Link>
      </li>
    </ul>
  );
};

export default CategoryItem;
