import React from "react";

import Button from "./Button";
import Input from "./Input";
import Textarea from "./Textarea";

const Popup = ({ initState, handleCancel }) => {
  return (
    <div className={`popup-wrapper ${initState ? "show" : ""}`}>
      <div className="popup-content-wrapper">
        <div className="popup_header">
          <h3>ENQUIRY FORM</h3>
        </div>
        <div className="popup_content">
          <form>
            <div className="row">
              <div className="col-md-6">
                <Input label="Full Name" placeholder="Full Name" />
              </div>
              <div className="col-md-6">
                <Input label="Product Name" placeholder="Product Name" />
              </div>
              <div className="col-md-6">
                <Input label="Mobile No" placeholder="Mobile No" />
              </div>
              <div className="col-md-6">
                <Input label="Email" placeholder="Email" />
              </div>
              <div className="col-md-6">
                <Input label="Address" placeholder="Address" />
              </div>
              <div className="col-md-6">
                <Input label="Landline No" placeholder="Landline No" />
              </div>
              <div className="col-md-12">
                <Textarea label="Message" placeholder="Message" />
              </div>
            </div>
          </form>
        </div>
        <div className="popup_footer">
          <Button type="button" label="Submit" className="view_all" />
          <Button
            type="button"
            label="Close"
            className="cancel_btn"
            onClick={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
