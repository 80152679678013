import React from "react";

import Title from "../Title";
import ClientItems from "./Clients";

const index = props => {
  return (
    <section
      className={`section client-section ${
        props.className ? props.className : ""
      }`}
    >
      <div className="container">
        <Title
          title="We work with"
          className="text-center"
          bgTitle="We work with"
        />
        <ClientItems />
      </div>
    </section>
  );
};

export default index;
