export const items = [
  { name: "Administratives", link: "/admin/dashboard" }
  // {
  //   name: "Banner Management",
  //   link: null,
  //   items: [
  //     { name: "Add Banner", link: "/admin/banner/new" },
  //     { name: "Banner List", link: "/admin/banners" }
  //   ]
  // }
];
