import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";

const Messenger = () => {
  return (
    <div>
      <MessengerCustomerChat pageId="asd" appId="asd" htmlRef="asd" />
    </div>
  );
};

export default Messenger;
