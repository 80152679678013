import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import store from "./store/store";

import AdminLayout from "./layout/Admin";
import PublicLayout from "./layout/Public";

import UserRoutes from "./routes/User";

import Dashboard from "./admin/screens/Dashboard";

import Homepage from "./screens/Home";
import ProductDetail from "./screens/Detail";
import AboutPage from "./screens/About";
import ContactPage from "./screens/Contact";
import ServicePage from "./screens/service";
import ProductPage from "./screens/products";

import Login from "./shared/Login";
import NotFound from "./shared/NotFound";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/admin/:path?/:query?" exact>
            <AdminLayout>
              <Switch>
                <Route exact path="/admin/dashboard" component={Dashboard} />
                <Route component={NotFound} />
              </Switch>
            </AdminLayout>
          </Route>
          <Route>
            <PublicLayout>
              <Switch>
                <Route path="/product/:id" exact component={ProductDetail} />
                <Route path="/about-us" exact component={AboutPage} />
                <Route path="/services" exact component={ServicePage} />
                <Route path="/products" exact component={ProductPage} />
                <Route path="/contact-us" exact component={ContactPage} />
                <Route path="/" exact component={Homepage} />
                <Route component={NotFound} />
              </Switch>
            </PublicLayout>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
