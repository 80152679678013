import React from "react";

import { AiTwotoneHeart } from "react-icons/ai";

const CompanyFooter = () => {
  return (
    <div className="company_footer">
      <div className="container">
        <p>
          Design and develop with <AiTwotoneHeart /> Mervix technologies
        </p>
      </div>
    </div>
  );
};

export default CompanyFooter;
