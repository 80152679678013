import React from "react";

import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import CompanyFooter from "../components/CompanyFooter";
import Messenger from "../components/Messenger";

const Public = (props) => {
  return (
    <div className="page-wrapper">
      <TopHeader />
      <Header />
      {props.children}
      <Footer />
      <ScrollToTop />
      <CompanyFooter />
      <Messenger />
    </div>
  );
};

export default Public;
